
import { Component, Watch, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { FormTextarea, FormBase, FormError, FormSubmit, FormInput } from "@/components/forms";
import { formatCurrency, formatDate } from "@/utils/formatters";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        BidRow: () => import("@/views/auth/dossier/tabs/parts/bids/bid-row.vue"),
        BidHeader: () => import("@/views/auth/dossier/tabs/parts/bids/bid-header.vue"),
        previewMediaModal: () => import("@/components/modals/preview-media.vue"),
        FormBase,
        FormTextarea,
        FormInput,
        FormError,
        FormSubmit,
    },
})
export default class PartActivityBids extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    
    @Getter("bid/all") bids!: Bid[];
    @Getter("bid/viewing") viewingBid!: Bid;
    @Getter("bid/winningBid") winningBid!: Bid | undefined;
    @Getter("bid/rejectedBids") rejectedBids!: Bid[];

    @Action("bid/index") indexBids!: BidIndex;
    @Action("bid/read") readBid!: BidRead;
    @Action("bid/patch") updateBid!: BidUpdate;
    @Action("activity/requestBidEmail") requestBidEmail!: (payload: { id: number; email: string }) => Promise<void>;

    loaded: boolean = false;
    action: string | null = null;
    reject_reason: string | null = null;

    formatCurrency = formatCurrency;

    formatDate = formatDate;

    requestBidEmailPayload: {
        email: string;
    } = { email: "" };

    init() {
        this.loaded = false;
        // @ts-ignore
        this.indexBids({
            activity_id: this.activity.id,
        }).then(() => (this.loaded = true));
    }

    onCopy() {
        this.$copyText(this.publicUrl);
        //navigator.clipboard.writeText(this.publicUrl).then(() => {
        this.$toast.open({ message: "Link gekopiëerd!" as string, type: "success", position: "bottom-right" });
        //});
    }

    resetAction() {
        this.action = null;
    }

    approveBid(id: number) {
        this.readBid({ id: id });
        this.action = "approve";
    }

    handleApproveBid() {
        const payload = {
            id: this.viewingBid.id,
            activity_id: this.activity.id,
            status: "APPROVED",
        };
        this.updateBid(payload).then(() => {
            this.$toast.open({ message: this.$t("views.bids.approve_success") as string, type: "success", position: "bottom-right" });
            this.action = null;
        });
    }

    rejectBid(id: number) {
        this.readBid({ id: id });
        this.action = "reject";
    }

    unveilBid(id: number) {
        this.readBid({ id: id });
        this.action = "unveal";
    }

    handleRejectBid() {
        const payload = {
            id: this.viewingBid.id,
            activity_id: this.activity.id,
            status: "REJECTED",
            rejection_reason: this.reject_reason,
        };
        this.updateBid(payload).then(() => {
            this.$toast.open({ message: this.$t("views.bids.reject_success") as string, type: "success", position: "bottom-right" });
            this.action = null;
            this.reject_reason = null;
        });
    }

    handleUnvealBid() {
        const payload = {
            id: this.viewingBid.id,
            activity_id: this.activity.id,
            visible: true,
        };
        this.updateBid(payload).then(() => {
            this.$toast.open({ message: this.$t("views.bids.unveal_success") as string, type: "success", position: "bottom-right" });
            this.action = null;
        });
    }

    async sendEmailRequest() {
        await this.requestBidEmail({ id: this.activity.id, email: this.requestBidEmailPayload.email });

        this.$toast.open({ message: this.$t("views.bids.bid_request_success") as string, type: "success", position: "bottom-right" });

        this.requestBidEmailPayload.email = "";

        this.action = null;
    }

    showBidDropdown(id: number) {
        this.readBid({ id: id });
        this.action = "show-dropdown";
    }

    undoRejectBid(id: number) {
        this.readBid({ id: id });
        this.action = "undo-reject";
    }

    handleUndoRejectBid() {
        const payload = {
            activity_id: this.activity.id,
            id: this.viewingBid.id,
            status: "CANDIDATE",
            rejection_reason: null,
        };
        this.updateBid(payload).then(() => {
            this.$toast.open({ message: this.$t("views.bids.reject_undo_success") as string, type: "success", position: "bottom-right" });
            this.action = null;
        });
    }

    handleCreateBid() {
        //this.action = "approve";
    }

    handleReopenBids() {
        if (this.winningBid) {
            const payload = {
                id: this.winningBid.id,
                activity_id: this.activity.id,
                status: "CANDIDATE",
            };
            this.updateBid(payload).then(() => {
                this.$toast.open({ message: this.$t("views.bids.reopen_success") as string, type: "success", position: "bottom-right" });
                this.action = null;
            });
        }
    }

    get publicUrl() {
        return process.env.VUE_APP_WEB_URL + this.$router.resolve({ name: "bid-form", params: { id: this.activity.public_id.toString() } }).href;
    }

    get newBids() {
        return this.bids.filter((bid: Bid) => bid.status === "CANDIDATE" && bid.visible === false);
    }

    get visibleBids() {
        return this.bids.filter((bid: Bid) => bid.status === "CANDIDATE" && bid.visible === true);
    }

    @Watch("$route", { immediate: true, deep: true })
    handleIdParamChanged() {
        this.init();
    }
}
